import React from 'react';

import styles from './Button.module.css';

export interface Props {
    title: string;
    width?: string;
    padding?: string;
}
export const ButtonGreen: React.FC<Props> = ({title, width, padding = '16px 32px'}) => {
    return (
        <>
            <button className={styles.buttonGreen} style={{width: width, padding: padding}}>
                {title}
            </button>
        </>
    );
};
export const ButtonGreenNav: React.FC<Props> = ({title, width, padding = '10px 20px'}) => {
    return (
        <>
            <button className={styles.buttonGreenNav} style={{width: width, padding: padding}}>
                {title}
            </button>
        </>
    );
};
