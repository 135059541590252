import React from 'react';

import styles from './CardLogin.module.css';
import {ButtonGreen} from 'components/Buttons/ButtonGreen';

export const CardLogin = ({title, image, text1, text2, text3, text4, text5}) => {
    return (
        <div className={styles.boxCard}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                }}></div>
            <div className={styles.boxCard_info}>
                <h3>{title}</h3>
                <p>{text1}</p>
                <p>{text2}</p>
                <p>{text3}</p>
                <p>{text4}</p>
                <p>{text5}</p>
                <ButtonGreen title="Acceder" width="100%" />
            </div>
        </div>
    );
};
