import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
// import {FaInstagram} from 'react-icons/fa';
// import {FiFacebook} from 'react-icons/fi';
import styles from './Nav.module.css';
import CommutieLogo from 'assets/img/logo_Commutie.svg';
import NavIcon from 'assets/icons/nav.svg';
import CloseIcon from 'assets/icons/close.svg';
import SelectIcon from 'assets/icons/select.svg';
import {ButtonGreenNav} from 'components/Buttons/ButtonGreen';
import {NavLink} from 'react-router-dom';

const NavPages = ({colorBack = '#bcf6e9'}) => {
    const {t} = useTranslation('global');
    const {i18n} = useTranslation('global');
    const [toggleMenu, setToggleMenu] = useState(false);
    const [toggleMenuLanguage, setToggleMenuLanguage] = useState(false);
    const [activeButtonLanguage, setActiveButtonLanguage] = useState(i18n.language);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    };
    const toggleLanguage = () => {
        setToggleMenuLanguage(!toggleMenuLanguage);
    };

    const clickedButtonLanguageOption = (e) => {
        const {name} = e.target;
        i18n.changeLanguage(name);
        setActiveButtonLanguage(name);
        setToggleMenuLanguage(false);
    };

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', changeWidth);
        return () => {
            window.removeEventListener('resize', changeWidth);
        };
    }, []);
    //const state = { active: null };
    return (
        <div className={`${styles.nav} ${styles.navPages}`} style={{backgroundColor: colorBack}}>
            <div className={styles.nav_logo}>
                <img src={CommutieLogo} alt="React Logo" className={styles.nav_logo_img} />
            </div>
            {(toggleMenu || screenWidth > 767) && (
                <>
                    <div className={styles.nav_nav}>
                        <NavLink
                            to="/"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            className={styles.nav_nav_link}>
                            {t('header.home')}
                        </NavLink>
                    </div>
                    <div className={styles.nav_language}>
                        {/* <NavLink to="/login">
                            <ButtonGreenNav title="Acceder"></ButtonGreenNav>
                        </NavLink> */}
                        <button onClick={toggleLanguage} className={styles.button_language}>
                            {t('header.language')}: <span>{activeButtonLanguage}</span>{' '}
                            <img src={SelectIcon} alt="Icono flecha desplegar"></img>
                        </button>
                        {toggleMenuLanguage && (
                            <div className={styles.nav_MenuLanguage}>
                                <button
                                    name="es"
                                    onClick={clickedButtonLanguageOption}
                                    className={
                                        activeButtonLanguage === 'es'
                                            ? `${styles.button_languageOption} ${styles.button_languageOption_active}`
                                            : styles.button_languageOption
                                    }>
                                    {t('header.spanish')}
                                </button>
                                <button
                                    name="en"
                                    onClick={clickedButtonLanguageOption}
                                    className={
                                        activeButtonLanguage === 'en'
                                            ? `${styles.button_languageOption} ${styles.button_languageOption_active}`
                                            : styles.button_languageOption
                                    }>
                                    {t('header.english')}
                                </button>
                            </div>
                        )}
                    </div>
                    {/* <div className={styles.nav_rrss}>
                        <a href="https://es-es.facebook.com" target="_blank" rel="noreferrer">
                            <FiFacebook />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                            <FaInstagram />
                        </a>
                    </div> */}
                </>
            )}
            <div className={styles.nav_burger} style={{backgroundColor: colorBack}}>
                <button onClick={toggleNav} className={styles.button_navMobile}>
                    <img src={toggleMenu === false ? NavIcon : CloseIcon} alt="Icon nav"></img>
                </button>
            </div>
        </div>
    );
};
export default NavPages;
