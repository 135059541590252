import NavPages from './../../components/Nav/NavPages';
import Footer from 'components/Footer/Footer';
import {Section} from 'components/Block/Section';
import {Trans, useTranslation} from 'react-i18next';
import styles from './Login.module.css';
import productBackRightTop from 'assets/back/product_back_right_top.svg';
import circleFull from 'assets/back/circleFull.svg';
import circleNoFull from 'assets/back/circleNoFull.svg';
import productBackLeftDown from 'assets/back/product_back_left_down.svg';
import {CardLogin} from './components/CardLogin';
import companies from 'assets/img/login1.png';
import transport from 'assets/img/login2.png';
import cities from 'assets/img/login3.png';
const Login = () => {
    const {t} = useTranslation('global');
    return (
        <>
            <Section paddingLeft="0" paddingRight="0" paddingTop="0" paddingBottom="0">
                <NavPages colorBack="white" />
            </Section>
            <Section colorBack="#f2fdfb">
                <div className={styles.login_title}>
                    <h1>Soluciones</h1>
                </div>
                <div className={styles.login_section}>
                    <CardLogin
                        title="Para empresas"
                        image={companies}
                        text1="Ahorro energético y reducción de costos operativos."
                        text2="Cumplimiento de regulaciones ambientales y responsabilidad corporativa."
                        text3="Mejora de la imagen de marca y reputación empresarial."
                        text4="Atracción y retención de talento comprometido con la sostenibilidad."
                        text5="Acceso a incentivos y beneficios fiscales por prácticas sostenibles."
                    />
                    <CardLogin
                        title="Para proveedores de transporte"
                        image={transport}
                        text1="Ampliación de oportunidades de negocio y diversificación de servicios."
                        text2="Cumplimiento de regulaciones y requisitos del mercado en evolución."
                        text3="Mejora de la eficiencia operativa y reducción de costos.
                        "
                        text4="Atracción de clientes comprometidos con la sostenibilidad y responsabilidad social."
                        text5="Fortalecimiento de la reputación como proveedor comprometido con la sostenibilidad.



                        "
                    />
                    <CardLogin
                        title="Para Smart Cities"
                        image={cities}
                        text1="Reducción de emisiones y mejora de la calidad del aire."
                        text2="Disminución de la congestión y mejora de la fluidez vial."
                        text3="Promoción de estilos de vida saludables y activos."
                        text4="Atracción de turismo y desarrollo económico sostenible."
                        text5="Cumplimiento de metas y compromisos internacionales sobre medio ambiente."
                    />
                </div>
                {/* Left */}
                <img className={styles.loginLeftTop_IMG} src={circleNoFull} alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.loginLeftCenter_IMG}
                    src={productBackLeftDown}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.loginLeftBottom_IMG}
                    src={circleNoFull}
                    alt="Imagen fondo figura geométrica"></img>
                {/* Right */}
                <img className={styles.loginRightTop_IMG} src={circleFull} alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.loginRightBottom_IMG}
                    src={productBackRightTop}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.loginCenterBottom_IMG}
                    src={circleFull}
                    alt="Imagen fondo figura geométrica"></img>
            </Section>
            <Section colorBack="#18191F" paddingTop="0" paddingBottom="0">
                <Footer />
            </Section>
        </>
    );
};
export default Login;
