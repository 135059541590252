import React from 'react';
import styled from 'styled-components';
export interface Props {
    icon?: string;
    iconAlt?: string;
    title?: string;
    subTitle?: string;
}
const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;   
    height:auto;
`;
const BoxImg = styled.div`
`;
const Title = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
    padding: 1.5rem 0;
`;
const SubTitle = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: justify;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #18191F;
`;
export const IconTop: React.FC<Props> = (props) => {
    return (
        <Box>
            <BoxImg>
                <img src={props.icon} alt={props.iconAlt} />
            </BoxImg>
            <Title>{props.title}</Title>
            <SubTitle>{props.subTitle}</SubTitle>
        </Box>
    );
};
