import {Trans, useTranslation} from 'react-i18next';

import styles from './Public.module.css';
import {Section} from 'components/Block/Section';
import {Color} from 'components/styles/colors.ts';
import {HeadingH2, Text} from 'components/Labels/Label';
import publicImg_01 from 'assets/img/public_01.webp';
import publicImg_02 from 'assets/img/public_02.webp';
import publicImg_03 from 'assets/img/public_03.webp';
import publicBackLeftTop from 'assets/back/public_back_left_top.svg';
import publicBackLeftMiddle from 'assets/back/public_back_left_middle.svg';
import publicBackRightTop from 'assets/back/public_back_right_top.svg';
import publicBackRightDown from 'assets/back/public_back_right_down.svg';

const Problem = () => {
    const {t} = useTranslation('global');

    return (
        <Section title="public" id="public" colorBack={Color.greenDark} paddingLeft="0" paddingRight="0">
            <div className={styles.public_section}>
                <div className={styles.public_boxTitle}>
                    <HeadingH2 title={<Trans t={t}>public.title</Trans>}></HeadingH2>
                </div>
                <div className={styles.public_boxImages}>
                    <div className={styles.public_boxImg1}>
                        <img src={publicImg_01} alt="Imagen dos personas estrechándose la mano"></img>
                    </div>
                    <div className={styles.public_boxImg2}>
                        <img src={publicImg_02} alt="Imagen personas celebrando"></img>
                    </div>
                    <div className={styles.public_boxImg3}>
                        <img src={publicImg_03} alt="Imagen hombre escalando"></img>
                    </div>
                </div>
                <div className={styles.public_boxText}>
                    <div className={styles.public_boxText_imgMobile1}> </div>
                    <div>
                        <h5>
                            <Trans t={t}>public.subTitle1</Trans>
                        </h5>
                        <Text title={<Trans t={t}>public.text1</Trans>} textAlign="justify"></Text>
                    </div>
                    <div className={styles.public_boxText_imgMobile2}></div>
                    <div>
                        <h5>
                            <Trans t={t}>public.subTitle2</Trans>
                        </h5>
                        <Text title={<Trans t={t}>public.text2</Trans>} textAlign="justify"></Text>
                    </div>
                    <div className={styles.public_boxText_imgMobile3}> </div>
                    <div>
                        <h5>
                            <Trans t={t}>public.subTitle3</Trans>
                        </h5>
                        <Text title={<Trans t={t}>public.text3</Trans>} textAlign="justify"></Text>
                    </div>
                </div>
                <img
                    className={styles.publicBackLeftTop_IMG}
                    src={publicBackLeftTop}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.publicBackLeftMiddle_IMG}
                    src={publicBackLeftMiddle}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.publicBackRightTop_IMG}
                    src={publicBackRightTop}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.publicBackRightDown_IMG}
                    src={publicBackRightDown}
                    alt="Imagen fondo figura geométrica"></img>
            </div>
        </Section>
    );
};
export default Problem;
