import React from 'react';
import {Link} from 'react-scroll';

import styles from './Button.module.css';
export interface Props {
    LinkDirection?: string;
    marginRight?: number;
    text?: string;
}
export const ButtonScrollLink: React.FC<Props> = ({LinkDirection, marginRight, text}) => {
    return (
        <>
            <Link
                to={`${LinkDirection}`}
                spy={true}
                smooth={true}
                offset={-70}
                duration={700}
                name="problem"
                className={styles.button}
                style={{
                    marginRight: marginRight,
                }}>
                {text}
            </Link>
        </>
    );
};
