import {Trans, useTranslation} from 'react-i18next';

import styles from './TextPages.module.css';

function TextCookies() {
    const {t} = useTranslation('global');

    return (
        <section className={styles.contentPolicies}>
            <h2>
                <Trans t={t} i18nKey="cookies.title" />
            </h2>
            <div>
                <Trans t={t} i18nKey="cookies.text.0" />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesUse" />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesType" />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesConsent" />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesRemove1" />
                <ul>
                    <li>
                        <span>Explorer:</span>{' '}
                        <a href="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">
                            https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                        </a>
                    </li>
                    <li>
                        <span>Microsoft Edge:</span>{' '}
                        <a href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                            https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
                        </a>
                    </li>
                    <li>
                        <span>Chrome:</span>{' '}
                        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=esttp://support.google.com/chrome/answer/95647/?hl=es">
                            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=esttp://support.google.com/chrome/answer/95647/?hl=es
                        </a>
                    </li>{' '}
                    <li>
                        {' '}
                        <span>Firefox: </span>{' '}
                        <a href="https://support.mozilla.org/es/kb/Borrar%20cookies">
                            https://support.mozilla.org/es/kb/Borrar%20cookies
                        </a>
                    </li>
                    <li>
                        <span>Safari:</span>{' '}
                        <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                            https://support.apple.com/es-es/guide/safari/sfri11471/mac
                        </a>
                    </li>
                    <li>
                        <span>Opera: </span>
                        <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                            https://help.opera.com/en/latest/web-preferences/#cookies
                        </a>
                    </li>
                </ul>
                <Trans t={t} i18nKey="cookies.cookiesRemove2" />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="cookies.cookiesModifications"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesTable1" />
            </div>
            <div>
                <Trans t={t} i18nKey="cookies.cookiesTable2" />
            </div>
        </section>
    );
}

export default TextCookies;
