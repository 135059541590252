import {Trans, useTranslation} from 'react-i18next';

import styles from './Product.module.css';
import {Section} from 'components/Block/Section';
import {IconTop} from 'components/Block/Box_IconTop';
import {Text, HeadingH4} from 'components/Labels/Label';
import wordlChangeIcon from 'assets/icons/wordlChange.svg';
import tracingIcon from 'assets/icons/tracing.svg';
import challengeIcon from 'assets/icons/challenge.svg';
import CommutieLogo from 'assets/img/logo_Commutie.svg';
import millenniumImg_11 from 'assets/img/product_millennium_11.jpg';
import millenniumImg_13 from 'assets/img/product_millennium_13.jpg';
import productBackRightTop from 'assets/back/product_back_right_top.svg';
import circleFull from 'assets/back/circleFull.svg';
import circleNoFull from 'assets/back/circleNoFull.svg';
import SliderMobile from 'components/Slider/SliderMobile.tsx';
import productBackLeftDown from 'assets/back/product_back_left_down.svg';

const Problem = () => {
    const {t} = useTranslation('global');
    return (
        <Section title="product" id="product" paddingLeft="0" paddingRight="0">
            <div className={styles.product_section}>
                <div className={styles.product_boxLeftTitle}>
                    <img className={styles.productCommutieLogo_IMG} src={CommutieLogo} alt="Commutie Logo" />
                </div>
                <div className={styles.product_boxLeft}>
                    <HeadingH4 title={<Trans t={t}>product.title2</Trans>}></HeadingH4>
                    <Text title={<Trans t={t}>product.text</Trans>} textAlign="justify"></Text>
                    <div className={styles.product_boxLeft_Millenium}>
                        <div>
                            <div>
                                <img
                                    className={styles.productMillennium}
                                    src={millenniumImg_11}
                                    alt="Mockup mobile"></img>
                            </div>
                            <div>
                                <h6>ODS 11</h6>
                                <p>
                                    <Trans t={t}>product.millennium11</Trans>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <img
                                    className={styles.productMillennium}
                                    src={millenniumImg_13}
                                    alt="Mockup mobile"></img>
                            </div>
                            <div>
                                <h6>ODS 13</h6>
                                <p>
                                    <Trans t={t}>product.millennium13</Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.product_boxRight}>
                    <SliderMobile></SliderMobile>
                </div>
                <div className={styles.product_boxBoxes}>
                    <div className={styles.product_boxBoxes_box1}>
                        <IconTop
                            icon={wordlChangeIcon}
                            iconAlt={<Trans t={t}>product.icon1_alt</Trans>}
                            title={<Trans t={t}>product.icon1_title</Trans>}
                            subTitle={<Trans t={t}>product.icon1_subTitle</Trans>}></IconTop>
                    </div>
                    <div>
                        <IconTop
                            icon={tracingIcon}
                            iconAlt={<Trans t={t}>product.icon2_alt</Trans>}
                            title={<Trans t={t}>product.icon2_title</Trans>}
                            subTitle={<Trans t={t}>product.icon2_subTitle</Trans>}></IconTop>
                    </div>
                    <div>
                        <IconTop
                            icon={challengeIcon}
                            iconAlt={<Trans t={t}>product.icon3_alt</Trans>}
                            title={<Trans t={t}>product.icon3_title</Trans>}
                            subTitle={<Trans t={t}>product.icon3_subTitle</Trans>}></IconTop>
                    </div>
                </div>
                <img
                    className={styles.productBackRightTop_IMG}
                    src={productBackRightTop}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.productBackRightDown_IMG}
                    src={circleNoFull}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.problemBackLeftTop_IMG}
                    src={circleNoFull}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.productBackLeftDownFull_IMG}
                    src={circleFull}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.productBackLeftDown_IMG}
                    src={productBackLeftDown}
                    alt="Imagen fondo figura geométrica"></img>
            </div>
        </Section>
    );
};
export default Problem;
