import {Trans, useTranslation} from 'react-i18next';

import styles from './TextPages.module.css';

function TextPrivacy() {
    const {t} = useTranslation('global');
    return (
        <section className={styles.contentPolicies}>
            <h2>
                <Trans t={t} i18nKey="legality.title" />
            </h2>
            <div>
                <Trans t={t} i18nKey="legality.subTitle" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.text" />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="legality.data"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.ambit" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.access" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.rights" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.utilization.web" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.utilization.blog" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.communications" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.responsibilities" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.links" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.links2" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.rrss" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.cookies" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.privacy" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.modification" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.observations" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.legislation" />
            </div>
            <div>
                <Trans t={t} i18nKey="legality.responsible" />
            </div>
        </section>
    );
}
export default TextPrivacy;
