import Header from 'pages/welcome/components/Header';
import Problem from './components/Problem';
import Product from './components/Product';
//import Opinion from 'components/Opinion';
import AboutUs from './components/AboutUs';
import Public from './components/Public';
import Download from './components/Download';
import Contact from './components/Contact';
import CookieBanner from './components/CookieBanner';
import Footer from 'components/Footer/Footer';

const Welcome = () => {
    return (
        <div>
            <Header />
            <Problem />
            <Product />
            <Public />
            <AboutUs />
            {/* <Opinion />           */}
            <Download />
            <Contact />
            <Footer />
            <CookieBanner />
        </div>
    );
};
export default Welcome;
