import {useEffect} from 'react';

import NavPages from 'components/Nav/NavPages';
import TextPrivacy from './components/TextPrivacy';
import Footer from 'components/Footer/Footer';
import {Section} from 'components/Block/Section';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Section paddingLeft="0" paddingRight="0" paddingTop="0" paddingBottom="0">
                <NavPages />
            </Section>
            <Section>
                <TextPrivacy />
            </Section>
            <Section colorBack="#18191F" paddingTop="0" paddingBottom="0">
                <Footer />
            </Section>
        </>
    );
};
export default Privacy;
