import {Trans, useTranslation} from 'react-i18next';

import styles from './AboutUs.module.css';
import {Section} from 'components/Block/Section';
import {Color} from 'components/styles/colors.ts';
import {HeadingH2, Text} from 'components/Labels/Label';

const AboutUs = () => {
    const {t} = useTranslation('global');

    return (
        <Section
            title="aboutUs"
            id="aboutUs"
            colorBack={Color.greenBlue}
            paddingTop="0"
            paddingBottom="0"
            paddingLeft="0"
            paddingRight="0">
            <div className={styles.aboutUs_section}>
                <div className={styles.box_aboutUs_left}></div>
                <div className={styles.box_aboutUs_right_title}>
                    <HeadingH2 title={<Trans t={t}>aboutUs.title</Trans>} color="white" textAlign="left"></HeadingH2>
                </div>
                <div className={styles.box_aboutUs_right}>
                    <Text title={<Trans t={t}>aboutUs.text1</Trans>} color="white" textAlign="justify"></Text>
                </div>
            </div>
        </Section>
    );
};
export default AboutUs;
