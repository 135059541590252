export const gridBaseSize = 4; // px
export enum Size {
    none = 0,
    xtiny = gridBaseSize, // 4 px           XXS
    tiny = gridBaseSize * 2, // 8 px        XS
    mini = gridBaseSize * 3, // 12 px       -
    small = gridBaseSize * 4, // 16 px      S
    base = gridBaseSize * 5, // 20px  -
    medium = gridBaseSize * 6, // 24 px     M
    large = gridBaseSize * 8, // 32px       L
    xlarge = gridBaseSize * 12, // 48 px    XL
    huge = gridBaseSize * 16, // 64 px      XXL
    xhuge = gridBaseSize * 48, // 192 px      XXL
}

export type SizeName = 'none' | 'xtiny' | 'tiny' | 'mini' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'huge';

export interface SizesInterface {
    none: Size;
    /** 5 px */
    xtiny: Size;
    /** 10 px */
    tiny: Size;
    /** 15 px */
    mini: Size;
    /** 20 px */
    small: Size;
    /** 25 px */
    base: Size;
    /** 30 px */
    medium: Size;
    /** 40 px */
    large: Size;
    /** 40 px */
    xlarge: Size;
    /** 60 px */
    huge: Size;

    xhuge: Size;
    scale: (factor: number) => number;
}

export const sizes = {
    none: Size.none,
    xtiny: Size.xtiny,
    tiny: Size.tiny,
    mini: Size.mini,
    small: Size.small,
    base: Size.base,
    medium: Size.medium,
    large: Size.large,
    xlarge: Size.xlarge,
    huge: Size.huge,
    xhuge: Size.huge,
    scale: (factor: number) => factor * gridBaseSize,
};
