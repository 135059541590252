import {Trans, useTranslation} from 'react-i18next';

import styles from './Download.module.css';
import {Section} from 'components/Block/Section';
import {Color} from 'components/styles/colors.ts';
import {ButtonScrollLink} from 'components/Buttons/Button_ScrollLink.tsx';
import PhoneMockup1Img from 'assets/img/download_lPhone Mockup.svg';
import PhoneMockup2Img from 'assets/img/download_lPhone Mockup2.svg';
import {HeadingH3, Text} from 'components/Labels/Label';
//import googlePlayLogo from 'assets/img/12_download_logo_appStore.svg'
//import appStoreLogo from 'assets/img/13_download_logo_googlePlay.svg'

const Contact = () => {
    const {t} = useTranslation('global');

    return (
        <Section
            title="download"
            id="download"
            colorBack={Color.greenDark}
            paddingTop="0"
            paddingBottom="0"
            paddingLeft="0"
            paddingRight="0">
            <div className={styles.download_section}>
                <div className={styles.download_boxLeft}>
                    <div className={styles.download_title}>
                        <HeadingH3 title={<Trans t={t}>download.title</Trans>}></HeadingH3>
                    </div>
                    <div>
                        <Text title={<Trans t={t}>download.text</Trans>}></Text>
                    </div>
                    <div>
                        <ButtonScrollLink LinkDirection="contact" text={t('download.button')}></ButtonScrollLink>
                    </div>
                    {/*<div className={styles.download_download}> 
                    <Text fontWeight='600' title={<Trans t={t}>download.download</Trans>}></Text>
                    <a href=''><img src={googlePlayLogo} alt="Google Play Logo" /></a>
                    <a href=''><img src={appStoreLogo} alt="App Store Logo" /> </a>          
                    </div> */}
                </div>
                <div className={styles.download_boxRight}>
                    <img src={PhoneMockup1Img} alt="Mockup phone" />
                    <img src={PhoneMockup2Img} alt="Mockup phone" />
                </div>
            </div>
        </Section>
    );
};
export default Contact;
