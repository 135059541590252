import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-scroll';
// import {FaInstagram} from 'react-icons/fa';
// import {FiFacebook} from 'react-icons/fi';
import {NavLink} from 'react-router-dom';
import styles from './Nav.module.css';
import CommutieLogo from 'assets/img/logo_Commutie.svg';
import NavIcon from 'assets/icons/nav.svg';
import CloseIcon from 'assets/icons/close.svg';
import SelectIcon from 'assets/icons/select.svg';
import {ButtonGreenNav} from 'components/Buttons/ButtonGreen';

const Header = () => {
    const {t} = useTranslation('global');
    const {i18n} = useTranslation('global');
    const [toggleMenu, setToggleMenu] = useState(false);
    const [toggleMenuLanguage, setToggleMenuLanguage] = useState(false);
    const [activeButton, setActiveButton] = useState('home');
    const [activeButtonLanguage, setActiveButtonLanguage] = useState(i18n.language);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    };
    const toggleLanguage = () => {
        setToggleMenuLanguage(!toggleMenuLanguage);
    };

    const clickedButtonHandler = (e) => {
        //console.log(e.target);
        const {name} = e.target;
        setActiveButton(name);
        //console.log(activeButton);
    };
    const clickedButtonLanguageOption = (e) => {
        const {name} = e.target;
        i18n.changeLanguage(name);
        setActiveButtonLanguage(name);
        setToggleMenuLanguage(false);
    };
    //COLOR FONDO BARRA NAVEGACIÓN SCROLL
    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 100) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', changeWidth);
        return () => {
            window.removeEventListener('resize', changeWidth);
        };
    }, []);
    //const state = { active: null };
    return (
        <div className={colorChange ? `${styles.nav} ${styles.navColorChange}` : styles.nav}>
            <div className={styles.nav_logo}>
                <Link to="home" spy={true} smooth={true} offset={-70} duration={700} name="home">
                    <img src={CommutieLogo} alt="React Logo" className={styles.nav_logo_img} />
                </Link>
            </div>
            {(toggleMenu || screenWidth > 767) && (
                <>
                    <div className={styles.nav_nav}>
                        {/* <Link
                            to="home"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            name="home"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'home'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.home')}
                        </Link> */}
                        <Link
                            to="problem"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            name="problem"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'problem'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.problem')}
                        </Link>
                        <Link
                            to="public"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            name="public"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'public'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.client')}
                        </Link>
                        <Link
                            to="product"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            name="product"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'product'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.product')}
                        </Link>
                        <Link
                            to="aboutUs"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            name="aboutUs"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'aboutUs'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.aboutUs')}
                        </Link>
                        {/*<Link
                  to="opinion"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500} 
                  name="opinion"
                  onClick={clickedButtonHandler}
                  className={activeButton === "opinion" ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}` : styles.nav_nav_link}>
                    {t("header.opinion")}
                </Link>*/}
                        <Link
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            name="contact"
                            onClick={clickedButtonHandler}
                            className={
                                activeButton === 'contact'
                                    ? `${styles.nav_nav_link} ${styles.nav_nav_link_active}`
                                    : styles.nav_nav_link
                            }>
                            {t('header.contact')}
                        </Link>
                    </div>
                    <div className={styles.nav_language}>
                        {/* <NavLink to="/login">
                            <ButtonGreenNav title="Acceder"></ButtonGreenNav>
                        </NavLink> */}
                        <button onClick={toggleLanguage} className={styles.button_language}>
                            {t('header.language')}: <span>{activeButtonLanguage} </span>
                            <img src={SelectIcon} alt="Icono flecha desplegar"></img>
                        </button>
                        {toggleMenuLanguage && (
                            <div className={styles.nav_MenuLanguage}>
                                <button
                                    name="es"
                                    onClick={clickedButtonLanguageOption}
                                    className={
                                        activeButtonLanguage === 'es'
                                            ? `${styles.button_languageOption} ${styles.button_languageOption_active}`
                                            : styles.button_languageOption
                                    }>
                                    {t('header.spanish')}
                                </button>
                                <button
                                    name="en"
                                    onClick={clickedButtonLanguageOption}
                                    className={
                                        activeButtonLanguage === 'en'
                                            ? `${styles.button_languageOption} ${styles.button_languageOption_active}`
                                            : styles.button_languageOption
                                    }>
                                    {t('header.english')}
                                </button>
                            </div>
                        )}
                    </div>
                    {/* <div className={styles.nav_rrss}>
                        <a href="https://es-es.facebook.com" target="_blank" rel="noreferrer">
                            <FiFacebook />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                            <FaInstagram />
                        </a>
                    </div> */}
                </>
            )}
            <div className={styles.nav_burger}>
                <button onClick={toggleNav} className={styles.button_navMobile}>
                    <img src={toggleMenu === false ? NavIcon : CloseIcon} alt="Icon nav"></img>
                </button>
            </div>
        </div>
    );
};
export default Header;
