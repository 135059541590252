import {Fragment} from 'react';
import {Route} from 'react-router-dom';
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook';
import Cookies from 'pages/subPages/Cookies';
import Privacy from 'pages/subPages/Privacy';
import Login from 'pages/subPages/Login';
import Legality from 'pages/subPages/Legality';
import Welcome from 'pages/welcome/Welcome';

function App() {
    const gtmParams = {id: 'GTM-T9QVG53'};
    return (
        <Fragment>
            <GTMProvider state={gtmParams}>
                <Route path="/" exact>
                    <Welcome />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/cookies">
                    <Cookies />
                </Route>
                <Route path="/privacy">
                    <Privacy />
                </Route>
                <Route path="/legality">
                    <Legality />
                </Route>
            </GTMProvider>
        </Fragment>
    );
}
export default App;
