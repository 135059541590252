export interface ColorsInterface {
    black: string;
    white: string;
    greenDark: string;
    greenLight: string;
    greenBlue: string;
}

export const Color: ColorsInterface = {
    black: '#000000',
    white: '#FFFFFF',
    greenDark: '#1CC09B',
    greenLight: '#BCF6E9',
    greenBlue: '#276C77',
};
