import React from 'react';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter} from 'react-router-dom';
import {hydrate, render} from 'react-dom';
import ReactGA from 'react-ga4';

import App from './navigation/App';
import './index.css';
import './local/i18n';
import '@fontsource/mulish';
ReactGA.initialize('G-XF60MQK1GL');
ReactGA.send('pageview');

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <CookiesProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CookiesProvider>
        </React.StrictMode>,
        rootElement,
    );
} else {
    render(
        <React.StrictMode>
            <CookiesProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CookiesProvider>
        </React.StrictMode>,
        rootElement,
    );
}
