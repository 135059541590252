import React from 'react';

import styles from './Button.module.css';

export interface Props {
    title?: string;
}
export const Button: React.FC<Props> = ({title}) => {
    return (
        <>
            <button className={`${styles.button} ${styles.buttonCookies}`}>{title}</button>
        </>
    );
};
