import React from 'react';
import styled from 'styled-components';
export interface Props {
    text?: string;
    icon?: string;
    iconAlt?: string;
}
const Box = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
`;
const BoxImg = styled.div`
    padding-right: 2rem;
`;
const Text = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 3rem;
    text-align: left;
    color: #000000;
`;
export const IconLeft: React.FC<Props> = (props) => {
    return (
        <Box>
            <BoxImg>
                <img src={props.icon} alt={props.iconAlt} />
            </BoxImg>
            <Text>{props.text}</Text>
        </Box>
    );
};
