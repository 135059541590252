import {Trans, useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';

import styles from './Header.module.css';
import Nav from '../../../components/Nav/Nav';
import {ButtonScrollLink} from 'components/Buttons/Button_ScrollLink.tsx';
import {Section} from 'components/Block/Section';
const Header = () => {
    const {t} = useTranslation('global');
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
    const isLandscape = useMediaQuery({query: '(orientation: landscape)'});

    return (
        <Section title="home" id="home" paddingLeft="0" paddingRight="0" paddingTop="0" paddingBottom="0">
            <div
                className={
                    isLandscape
                        ? `${styles.sectionHeader} ${styles.sectionHeader_horizontal}`
                        : `${styles.sectionHeader} ${styles.sectionHeader_vertical}`
                }>
                <Nav />
                {isPortrait && <div className={styles.header_vertical_fondo}></div>}
                <div className={styles.header_main}>
                    {isLandscape && (
                        <h1>
                            <Trans t={t}>header.title</Trans>
                        </h1>
                    )}
                    {isPortrait && (
                        <h1>
                            <Trans t={t}>header.title2</Trans>
                        </h1>
                    )}
                    <div>
                        <ButtonScrollLink LinkDirection="problem" text={t('header.button')}></ButtonScrollLink>
                    </div>
                </div>
            </div>
        </Section>
    );
};
export default Header;
