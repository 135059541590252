import {Trans, useTranslation} from 'react-i18next';

import styles from './TextPages.module.css';

function TextPrivacy() {
    const {t} = useTranslation('global');

    return (
        <section className={styles.contentPolicies}>
            <h2>
                <Trans t={t} i18nKey="privacy.title" />
            </h2>
            <div>
                <Trans t={t} i18nKey="privacy.responsible" />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="privacy.general"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.beginning" />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.dataSale" />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.process" />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.purposes" />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.exchange" />
                <table>
                    <tr>
                        <th>
                            <Trans t={t} i18nKey="privacy.exchangeTable.name" />
                        </th>
                        <th>
                            <Trans t={t} i18nKey="privacy.exchangeTable.service" />
                        </th>
                        <th>
                            <Trans t={t} i18nKey="privacy.exchangeTable.location" />
                        </th>
                        <th>
                            <Trans t={t} i18nKey="privacy.exchangeTable.url" />
                        </th>
                    </tr>
                    <tr>
                        <td>Amazon.com, Inc.</td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_a_2" />
                        </td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_a_3" />
                        </td>
                        <td>
                            <a
                                href="
https://services.amazon.com/privacy-policy.html

">
                                https://services.amazon.com/privacy-policy.html
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Firebase</td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_b_2" />
                        </td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_b_3" />
                        </td>
                        <td>
                            <a href="https://policies.google.com/privacy https://firebase.google.com/support/privacy">
                                https://policies.google.com/privacy https://firebase.google.com/support/privacy
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google Analytics (Google LLC)</td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_c_2" />
                        </td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_c_3" />
                        </td>
                        <td>
                            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                        </td>
                    </tr>
                    <tr>
                        <td>Sentry</td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_d_2" />
                        </td>
                        <td>
                            <Trans t={t} i18nKey="privacy.exchangeTable.row_d_3" />
                        </td>
                        <td>
                            <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.international" />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.time" />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="privacy.rights"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="privacy.eliminate"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.exclusion" />
            </div>
            <div>
                <Trans
                    t={t}
                    i18nKey="privacy.security"
                    components={{a: <a href="mailto:info@commutie.com">info@commutie.com</a>}}
                />
            </div>
            <div>
                <Trans t={t} i18nKey="privacy.changes" />
            </div>
        </section>
    );
}
export default TextPrivacy;
