import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
// import {FaInstagram} from 'react-icons/fa';
// import {FiFacebook} from 'react-icons/fi';

import styles from './Footer.module.css';
import {Section} from 'components/Block/Section';
import CommutieLogoFooter from 'assets/img/logo_Commutie_white.svg';

export const Footer = () => {
    const {t} = useTranslation('global');

    return (
        <Section title="footer" id="footer" colorBack="#18191F" paddingTop="0" paddingBottom="0">
            <div className={styles.footer_section}>
                <div className={styles.footer_logo}>
                    <img className={styles.footer_logo_img} src={CommutieLogoFooter} alt="Commutie Logo" />
                </div>
                <div className={styles.footer_nav}>
                    <NavLink to="/cookies" className={styles.footer_link}>
                        {t('footer.cookies.label')}
                    </NavLink>
                    <NavLink to="/privacy" className={styles.footer_link}>
                        {t('footer.privacy.label')}
                    </NavLink>
                    <NavLink to="/legality" className={styles.footer_link}>
                        {t('footer.legalWarning.label')}
                    </NavLink>
                </div>
                <div className={styles.footer_text}>
                    <div className={styles.footer_copyright}>
                        <p>
                            {new Date().getFullYear()} {t('footer.rights.text')}
                        </p>
                    </div>
                    {/* <div className={styles.footer_rrss}>
                    <a href="https://es-es.facebook.com" target="_blank" rel="noreferrer">
                        <FiFacebook />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <FaInstagram />
                    </a>
                </div> */}
                </div>
            </div>
        </Section>
    );
};
export default Footer;
