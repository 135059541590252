import {Trans, useTranslation} from 'react-i18next';

import styles from './Problem.module.css';
import {Section} from 'components/Block/Section';
import {Color} from 'components/styles/colors.ts';
import {HeadingH2, Text} from 'components/Labels/Label';
import problemBackRight from 'assets/back/problem_back_right.svg';
import problemBackLeftTop from 'assets/back/problem_back_left_top.svg';
import problemBackLeftDownCircle from 'assets/back/problem_back_left_down_circle.svg';
import circleFull from 'assets/back/circleFullWhite.svg';
import circleNoFull from 'assets/back/circleNoFull.svg';

const Problem = () => {
    const {t} = useTranslation('global');

    return (
        <Section title="problem" id="problem" colorBack={Color.greenLight}>
            <div className={styles.problem_section}>
                <div className={styles.problem_boxTitle}>
                    <HeadingH2 title={<Trans t={t}>problem.title</Trans>}></HeadingH2>
                </div>
                <div className={styles.problem_boxLeft}>
                    <Text title={<Trans t={t}>problem.text1</Trans>} textAlign="justify"></Text>
                    <Text title={<Trans t={t}>problem.text2</Trans>} textAlign="justify"></Text>
                </div>
                <div className={styles.problem_boxRight}></div>
                <img
                    className={styles.problemBackRight_IMG}
                    src={problemBackRight}
                    alt="Imagen fondo circulo figura geométrica"></img>
                <img
                    className={styles.problemBackLeftTop_IMG}
                    src={problemBackLeftTop}
                    alt="Imagen fondo circulo figura geométrica"></img>
                <img
                    className={styles.problemBackLeftDownCircle_IMG}
                    src={problemBackLeftDownCircle}
                    alt="Imagen fondo circulo figura geométrica"></img>
                <img
                    className={styles.problemBackLeftMobilNoFull_IMG}
                    src={circleNoFull}
                    alt="Imagen fondo circulo figura geométrica"></img>
                <img
                    className={styles.problemBackLeftMobilFull_IMG}
                    src={circleFull}
                    alt="Imagen fondo circulo figura geométrica"></img>
            </div>
        </Section>
    );
};
export default Problem;
