import {Trans, useTranslation} from 'react-i18next';

import styles from './Contact.module.css';
import {Section} from 'components/Block/Section';
import Form from 'components/Form/Form';
import {IconLeft} from 'components/Block/Box_IconLeft';
import {HeadingH2} from 'components/Labels/Label';
import PhoneIcon from 'assets/icons/phone.svg';
import EmailIcon from 'assets/icons/email.svg';
import AddressIcon from 'assets/icons/direction.svg';
import contactBackRight from 'assets/back/contact_back_rigth.svg';
import contactBackLeft from 'assets/back/contact_back_left.svg';

const Contact = () => {
    const {t} = useTranslation('global');
    return (
        <Section title="contact" id="contact">
            <div className={styles.contact_section}>
                <div className={styles.contact_title}>
                    <HeadingH2 title={<Trans t={t}>contact.title</Trans>}></HeadingH2>
                </div>
                <div className={styles.contact_boxLeft}>
                    <div>
                        <IconLeft
                            text={<Trans t={t}>contact.email</Trans>}
                            icon={EmailIcon}
                            iconAlt="Email icon"></IconLeft>
                        <IconLeft
                            text={<Trans t={t}>contact.address</Trans>}
                            icon={AddressIcon}
                            iconAlt="Address Icon"></IconLeft>
                        <IconLeft
                            text={<Trans t={t}>contact.phone</Trans>}
                            icon={PhoneIcon}
                            iconAlt="Phone icon"></IconLeft>
                    </div>
                </div>
                <div className={styles.contact_boxRight}>
                    <Form></Form>
                </div>
                <img
                    className={styles.contactBackLeft_IMG}
                    src={contactBackLeft}
                    alt="Imagen fondo figura geométrica"></img>
                <img
                    className={styles.contactBackRight_IMG}
                    src={contactBackRight}
                    alt="Imagen fondo figura geométrica"></img>
            </div>
        </Section>
    );
};
export default Contact;
