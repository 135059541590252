import React from 'react';
import MediaQuery from 'react-responsive'
export interface Props {
    fontSize?: number;
    color?: string;
    title?: string;
    fontWeight?: number;
    textAlign?:'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}

export const HeadingH2: React.FC<Props> = ({color, title, textAlign}) => {    
    return (         
        <h2
            style={{
                color: color,         
                textAlign: textAlign ? textAlign : 'center',
            }}>
            {title}
        </h2>
    );
};
export const HeadingH3: React.FC<Props> = ({color, title, textAlign}) => {
    return (
            <h3
                style={{
                    color: color,              
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </h3>
    );
};
export const HeadingH4: React.FC<Props> = ({color, title, textAlign}) => {
    return (
        <>
        <MediaQuery minWidth={1400}>
            <h4
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '4rem',
                    fontWeight: '700',
                    lineHeight: '5.5rem',                  
                    textAlign: textAlign ? textAlign : 'left', 
                    padding: '0 0 10px 0'
                        
                    }}>
                        {title}
            </h4> 
        </MediaQuery> 
        <MediaQuery minWidth={1024} maxWidth={1399}>
            <h4
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '4rem',
                    fontWeight: '700',
                    lineHeight: '5.5rem',                  
                    textAlign: textAlign ? textAlign : 'left', 
                    padding: '0 0 10px 0'    
                }}>
                    {title}
            </h4>            
        </MediaQuery>
        <MediaQuery minWidth={767} maxWidth={1023}> 
            <h4
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '2.8rem',
                    fontWeight: '700',
                    lineHeight: '3.5rem',                  
                    textAlign: textAlign ? textAlign : 'left', 
                    padding: '0 0 10px 0'    
                }}>
                    {title}
            </h4>
        </MediaQuery> 
        <MediaQuery minWidth={576} maxWidth={766}>
            <h4
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '2.7rem',
                    fontWeight: '700',
                    lineHeight: '3.5rem',                 
                    textAlign: textAlign ? textAlign : 'left', 
                    padding: '0 0 10px 0'    
                }}>
                    {title}
            </h4>
        </MediaQuery> 
        <MediaQuery maxWidth={575}>
            <h4
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '2.5rem',
                    fontWeight: '700',
                    lineHeight: '3.5rem',                  
                    textAlign: textAlign ? textAlign : 'left', 
                    padding: '0 0 10px 0'    
                }}>
                    {title}
            </h4>
        </MediaQuery>  
        </>        
    );
};
export const Text: React.FC<Props> = ({color, title, textAlign, fontWeight}) => {
    return (
        <>
        <MediaQuery minWidth={1400}>
            <p
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '2rem',
                    fontWeight: fontWeight ? fontWeight : '500',
                    lineHeight: '3.5rem',
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </p>
        </MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1399}>
            <p
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '2rem',
                    fontWeight: fontWeight ? fontWeight : '500',
                    lineHeight: '3.5rem',
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </p>
        </MediaQuery>
        <MediaQuery minWidth={767} maxWidth={1023}> 
            <p
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '1.7rem',
                    fontWeight: fontWeight ? fontWeight : '500',
                    lineHeight: '2.5rem',
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </p>
        </MediaQuery>        
        <MediaQuery minWidth={576} maxWidth={766}>
            <p
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '1.6rem',
                    fontWeight: fontWeight ? fontWeight : '500',
                    lineHeight: '2.5rem',
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </p>
        </MediaQuery>
        <MediaQuery maxWidth={575}>
            <p
                style={{
                    color: color,
                    fontFamily: 'Mulish',
                    fontSize: '1.5rem',
                    fontWeight: fontWeight ? fontWeight : '500',
                    lineHeight: '2.5rem',
                    textAlign: textAlign ? textAlign : 'left',
                }}>
                {title}
            </p>
        </MediaQuery>
        </>
    );
};

