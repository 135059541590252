import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import './SliderMobile.css';
import mobileGroup1 from 'assets/img/product_groupMockups1.svg';
import mobileGroup2 from 'assets/img/product_groupMockups2.svg';
import mobileGroup3 from 'assets/img/product_groupMockups3.svg';

export default function App() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={60}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 60,
                    },
                }}
                modules={[Pagination]}
                className="mySwiper">
                <SwiperSlide>
                    <img src={mobileGroup1} alt="Mockup mobile"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={mobileGroup2} alt="Mockup mobile"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={mobileGroup3} alt="Mockup mobile"></img>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
