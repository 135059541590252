import * as React from 'react';
import styled from 'styled-components';
import {Element} from 'react-scroll';
import MediaQuery from 'react-responsive';

import {Size} from 'components/styles/size';

export interface Props {
    title: string;
    id: string;
    colorBack: string;
    children?: React.ReactNode;
    paddingTop?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
}
const SectionBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    position: relative;
`;
export const Section: React.FC<Props> = ({
    title,
    id,
    colorBack,
    children,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
}) => {
    return (
        <>
            <MediaQuery minWidth={1400}>
                <SectionBox
                    style={{
                        backgroundColor: colorBack,
                        paddingTop: paddingTop ? paddingTop : Size.huge,
                        paddingBottom: paddingBottom ? paddingBottom : Size.huge,
                        paddingLeft: paddingLeft ? paddingLeft : Size.xhuge,
                        paddingRight: paddingRight ? paddingRight : Size.xhuge,
                        position: 'relative',
                        zIndex: '-10 !important',
                    }}>
                    <Element name={title} id={id}>
                        {children}
                    </Element>
                </SectionBox>
            </MediaQuery>
            <MediaQuery minWidth={1024} maxWidth={1399}>
                <SectionBox
                    style={{
                        backgroundColor: colorBack,
                        paddingTop: paddingTop ? paddingTop : Size.huge,
                        paddingBottom: paddingBottom ? paddingBottom : Size.huge,
                        paddingLeft: paddingLeft ? paddingLeft : Size.huge,
                        paddingRight: paddingRight ? paddingRight : Size.huge,
                    }}>
                    <Element name={title} id={id}>
                        {children}
                    </Element>
                </SectionBox>
            </MediaQuery>
            <MediaQuery minWidth={767} maxWidth={1023}>
                <SectionBox
                    style={{
                        backgroundColor: colorBack,
                        paddingTop: paddingTop ? paddingTop : Size.large,
                        paddingBottom: paddingBottom ? paddingBottom : Size.large,
                        paddingLeft: paddingLeft ? paddingLeft : Size.large,
                        paddingRight: paddingRight ? paddingRight : Size.large,
                    }}>
                    <Element name={title} id={id}>
                        {children}
                    </Element>
                </SectionBox>
            </MediaQuery>
            <MediaQuery minWidth={576} maxWidth={766}>
                <SectionBox
                    style={{
                        backgroundColor: colorBack,
                        paddingTop: paddingTop ? paddingTop : Size.medium,
                        paddingBottom: paddingBottom ? paddingBottom : Size.medium,
                        paddingLeft: paddingLeft ? paddingLeft : Size.medium,
                        paddingRight: paddingRight ? paddingRight : Size.medium,
                    }}>
                    <Element name={title} id={id}>
                        {children}
                    </Element>
                </SectionBox>
            </MediaQuery>
            <MediaQuery maxWidth={575}>
                <SectionBox
                    style={{
                        backgroundColor: colorBack,
                        paddingTop: paddingTop ? paddingTop : Size.medium,
                        paddingBottom: paddingBottom ? paddingBottom : Size.medium,
                        paddingLeft: paddingLeft ? paddingLeft : Size.medium,
                        paddingRight: paddingRight ? paddingRight : Size.medium,
                    }}>
                    <Element name={title} id={id}>
                        {children}
                    </Element>
                </SectionBox>
            </MediaQuery>
        </>
    );
};
